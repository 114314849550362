"use client";
import { Property } from "@/@types/global";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardDescription, CardFooter, CardHeader } from "@/components/ui/card";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { getPropertiesInfiniteScroll } from "@/services/property";
import { Bath, MapPin, X } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState, useEffect, ReactNode, useContext, createContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { SquareLibrary } from "lucide-react";
import CustomModal from "@/components/globals/custom-model";
import { useModal } from "@/contexts/modal-provider";
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { OrderView } from "@/app/(main)/_components/order/order.view";

// Context to manage modal state
type ContextType = {
  isOpen: boolean;
  setOpen(value: boolean): void;
};
const context = createContext<ContextType>({
  isOpen: false,
  setOpen: () => {},
});

// Provider for the modal context
type Props = {
  children: ReactNode;
};
export const ViewsPropertiesProvider = ({ children }: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const url = usePathname();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { data, isLoading, fetchNextPage } = getPropertiesInfiniteScroll({ maps: true });

  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (!isMounted) return null;

  return (
    <context.Provider value={{ isOpen, setOpen: setIsOpen }}>
      {children}
      {url == "/" && isOpen && (
        <div className="fixed bottom-2 p-2 flex z-[500] w-full flex-col items-center justify-center">
          <div
            onClick={() => setIsOpen(false)}
            className="z-[800] right-2 -top-7 cursor-pointer absolute bg-white rounded-full w-[30px] h-[30px] flex items-center justify-center"
          >
            <X size={20} />
          </div>
          <Swiper
            className="w-full"
            spaceBetween={30}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            breakpoints={{
              400: { slidesPerView: 2, spaceBetween: 20 },
              640: { slidesPerView: 3, spaceBetween: 20 },
              768: { slidesPerView: 4, spaceBetween: 25 },
              1024: { slidesPerView: 5, spaceBetween: 30 },
              1200: { slidesPerView: 6, spaceBetween: 30 },
            }}
          >
            {data?.pages.map((page) =>
              page.data.map((card: any) => (
                <SwiperSlide key={card.id}>
                  <CardView data={card} />
                </SwiperSlide>
              ))
            )}

            <SwiperSlide className="h-[150px]">
              <div ref={ref} />
              <Skeleton  className=" w-full" />
            </SwiperSlide>
          </Swiper>
        </div>
      )}
      {/* <p>TESTE</p>
      {isLoading && (
        <div className="grid w-full gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {Array.from({ length: 10 }).map((_, idx) => (
            <Skeleton key={idx} className="h-[350px] w-full" />
          ))}
        </div>
      )} */}
    </context.Provider>
  );
};

// Custom hook to use the modal context
export const useViewsPropertiesContext = () => {
  return useContext(context);
};

// Card View Component
const CardView = ({ data }: { data: Property }) => {
  const imgs = data?.attachments.map((img, index) => {
    if (index < 3)
      return (
        <CarouselItem className="w-full" key={index}>
          <img
            src={img}
            alt={`Image da propriedade ${index} do usuário ${data.customer?.name}`}
            className="!w-full h-full object-cover rounded-t-lg"
          />
        </CarouselItem>
      );
  });

  return (
    <Card className={"flex flex-col w-full bg-white"}>
      <div className="relative">
        {imgs.length > 0 ? (
          <Carousel className="relative flex w-full h-[150px]">
            <CarouselContent>{imgs}</CarouselContent>
            <CarouselPrevious className="left-0 bg-white" />
            <CarouselNext className="right-0 bg-white" />
          </Carousel>
        ) : (
          <Image
            src={"/Item_sem_imagem.svg"}
            alt={`Sem imagem da propriedade do usuário ${data.customer?.name}`}
            quality={10}
            width={0}
            height={0}
            layout="responsive"
            className="!w-full !h-[200px] object-contain rounded-t-lg"
          />
        )}
      </div>
      <Link href={`/property/${data.slug}`} className="p-4">
        <CardHeader className="p-0">
          <CardDescription className="flex items-center p-0 w-full gap-2">
            <MapPin size={20} className="w-6 text-gray-500" />
            <p className="truncate text-sm text-[#7B818F]">{data?.location.fullAddress}</p>
          </CardDescription>
        </CardHeader>
        <h4 className="text-sm uppercase text-[#3C4453] mt-2">detalhes:</h4>
        <div className="flex items-center justify-between gap-1">
          <CardDescription className="flex gap-2">
            <Image src={"/bad.svg"} width={20} height={20} alt="ícone de cama" />
            <p className="text-x text-[#7B818F] truncate">{data?.details?.bathrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Bath size={20} className="text-[#7B818F]" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.bathrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src={"/subway.svg"} width={20} height={20} alt="ícone de cama" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.areaTotal} m²</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src={"/garage.svg"} width={20} height={20} alt="ícone de cama" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.areaTotal}</p>
          </CardDescription>
        </div>
      </Link>
      <CardFooter className="w-full flex flex-1 flex-col p-2">
        <div className="flex items-center w-full overflow-hidden justify-between gap-2">
          <p className="truncate">
            {data?.price?.toLocaleString("pt-BR", {
              currency: "BRL",
              style: "currency",
            })}
          </p>
          {data?.customer?.id && (
            <Link href={`/agency/${data?.customer?.id}`} className="flex items-center gap-2 hover:underline">
              <Avatar className="w-[30px] h-[30px]">
                <AvatarImage src={data.customer?.avatar} alt={data.customer?.name} />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
              <p className="text-xs">{data?.customer?.name}</p>
            </Link>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};
