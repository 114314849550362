"use client";

import { getCookie } from "./cookies";

/**
 * A simple wrapper around fetch that throws an error if the response is not 200.
 * @param url The URL to fetch. Must be one of the ApiEndpoints enum.
 * @param body The request body to send. Optional.
 * @returns The JSON response.
 */
export async function fetcher<T = any>(
  url: string,
  body?: RequestInit
): Promise<T> {
  const token = getCookie("session")
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token?.replaceAll(`"`,"")}`,
      },
      // credentials: "include",
      ...body,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Aconteceu um error inesperado");
  }
}
