"use client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProgressBar } from "next-nprogress-bar";
import type { ReactNode } from "react";
import { TooltipProvider } from "../ui/tooltip";
import { ClusterProvider } from "./clusters-provider";
import ModalProvider from "../../contexts/modal-provider";
import { Toaster } from 'sonner';
import { ViewsPropertiesProvider } from "@/contexts/views-properties";

type Props = {
	children: ReactNode;
};

export default function Provider({ children }: Props) {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 24 * (60 * 1000),
			},
		},
	});
	return (
		<QueryClientProvider client={queryClient}>
				<TooltipProvider>
					<ClusterProvider>
						<ModalProvider>
							<ViewsPropertiesProvider>
									{children}
								<AppProgressBar
									height="4px"
									color="#063B89"
									options={{ showSpinner: false }}
									shallowRouting
									/>
								<Toaster position="top-center" richColors />
							</ViewsPropertiesProvider>
						</ModalProvider>
					</ClusterProvider>
				</TooltipProvider>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	);
}
