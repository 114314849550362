import type React from "react";
import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import Supercluster, { type AnyProps, type PointFeature } from "supercluster";
type MarkerBack = {
	id: string | number;
	transaction: string;
	latitude: string;
	longitude: string;
};

type ContextType = {
	data: MarkerBack[];
	ids: string[]
	setData: (data: MarkerBack[]) => void;
	setIds: (ids: string[]) => void;
	cluster: Supercluster | null;
};

const ClusterContext = createContext<ContextType>({
	data: [],
	setData: (data: MarkerBack[]) => { },
	setIds: (ids: string[]) => { },
	cluster: null,
	ids: []
});

export const ClusterProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [data, setData] = useState<MarkerBack[]>([]);
	const [cluster, setCluster] = useState<Supercluster | null>(null);
	const [ids, setIds] = useState<string[]>([]);
	useEffect(() => {
		const superCluster = new Supercluster<AnyProps>({
			radius: 50,
			extent: 150,
			maxZoom: 30,
		});
		if (data?.length > 0) {
			const clusterData = data.map((marker: MarkerBack) => {
				return {
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [
							Number.parseFloat(marker.longitude),
							Number.parseFloat(marker.latitude),
						],
					},
					properties: marker,
				}
			}) as any
			superCluster.load(clusterData);
			setCluster(superCluster);
		}
	}, [data]);

	return (
		<ClusterContext.Provider
			value={{
				data,
				cluster,
				setData,
				ids,
				setIds
			}}
		>
			{children}
		</ClusterContext.Provider>
	);
};

export const useClusterContext = () => {
	const context = useContext(ClusterContext);
	if (context === undefined) {
		throw new Error("useMapContext must be used within a MapProvider");
	}
	return context;
};
