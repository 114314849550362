"use client"
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import type { Property } from "../@types/global";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}
// export function debounce(callback: Function, delay: number) {
// 	let timeoutId: ReturnType<typeof setTimeout>;
// 	return (...args: any[]) => {
// 		clearTimeout(timeoutId);
// 		timeoutId = setTimeout(() => {
// 			callback.apply([null, ...args]);
// 		}, delay);
// 	};
// }

export const filterZoomMap = (
	data: Array<any>,
	currentZoom: number,
): Property[] => {
	if (currentZoom < 12) {
		return data.slice(0, Math.ceil(data.length * 0.4));
	} if (currentZoom < 15) {
		data.slice(0, Math.ceil(data.length * 0.7));
	}
	return data;
};

export const transformLocation = async (
	address: google.maps.GeocoderResult,
) => {
	const zipCode = address.address_components.find((component) =>
		component.types.includes("postal_code"),
	)?.long_name;
	if (!zipCode) return { city: null, neighborhood: null, state: null, zipCode };
	const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
	const output = await response.json();
	const { data } = output;
	return {
		city: data.localidade,
		neighborhood: data.bairro,
		state: data.uf,
		zipCode,
	};
};

export const formatCurrency = (value = 0) => {
	return value.toLocaleString("pt-BR", {
		style: "currency",
		currency: "BRL",
	});
};


export const returnIdsLimitedParams = (idsParams: string[], page: number): string => {
  const ID_MAX = 10;
  if (idsParams.length < ID_MAX) {
    return idsParams.join("&ids=");
  }
  if (!idsParams || idsParams.length === 0) return "";
  const startIdx = page * ID_MAX;
  const endIdx = Math.min(startIdx + ID_MAX, idsParams.length);
  const ids = idsParams.slice(startIdx, endIdx);
  return ids.join("&ids=");
};
export const getLatLngByIP = async () => {
	try {
		const response = await fetch(`https://api.ipbase.com/v1/json/`);
		const output = await response.json();
		const { data } = output;
		const { latitude, longitude } = data
		return { lat: parseFloat(latitude), lng: parseFloat(longitude) };
	} catch (error) {
		console.error("Failed to fetch location data", error);
		return {
			lat: -23.533773,
			lng: -46.625290
		};
	}
};
